const navTitles = [
  {
    name: "About",
    link: "/about"
  },
  {
    name: "Services",
    link: "/services"
  },
  {
    name: "Methods",
    link: "/methods"
  },
  {
    name: "Contact Us",
    link: "/contact"
  }
];

export default navTitles;
